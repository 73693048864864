input {
  width: 100%;
  height: 50px;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 10px;
  border-style: none;
  font-size: 20px;
}

input:focus,
input[focus] {
  //background-color: white;
}

input:disabled,
input[disable] {
  opacity: 0.5;
  cursor: not-allowed;
}

.input-error {
  border: red 2px solid !important;
}

.icon-input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: #fff;
  border-radius: 10px;
  border-style: none;

  img {
    margin-left: 10px;
  }

  /*
  input {
    padding: 0 20px;
    height: unset;
    background-color: #ffffff;
  } */
}
