$black: #252525;
$white: #f2f2f2;
$light-grey: #e3e3e3;
$grey: #c6c6c6;
$dark-grey: #7c7c7c;
$primary: #68a433; // modificato
$secondary: #2a6a3a; // modificato
$tertiary: #eaecee;
$confirm: #73b392;
$dark-gradient: linear-gradient(180deg, $primary 0%, $secondary 100%);
$light-gradient: linear-gradient(180deg, $grey 0%, $white 100%);
$background: #fff; // modificato //ccd1d1
$text: $black;
$text-light: $white;
$primaryBonatti: #d50000;

.background-white {
  background-color: #eaecee; // modificato
}

.spinner-grow {
  background-color: $primary;
}

// new palette
// #d4aa7d beige scuro
// #efd09e beige chiaro
// #2a6a3a verde scuro
