.full-page {
  min-height: 100vh;

  &.center {
    display: flex;
  }
}

.card {
  background-color: #eaecee;
  height: stretch;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 2rem;
  border: none;
  border-radius: 10px;
  box-shadow: -4px -4px 10px rgb(0 0 0 / 10%), 4px 4px 10px rgb(0 0 0 / 10%);

  &.card-fill {
    min-height: 100%;
    justify-content: start;
  }

  &.card-large {
    max-width: 100%;
    padding: 2rem 5%;
    box-shadow: 4px 4px 10px rgb(0 0 0 / 10%);
  }

  &.card-login {
    width: 30%;
    max-width: 100%;
    padding: 2rem 5%;
    box-shadow: 4px 4px 10px rgb(0 0 0 / 10%);
  }

  .info-content {
    padding: 14px 0 !important;
  }

  ul {
    list-style-type: disc !important;
    color: $primary;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;

    a {
      color: $primary;
      text-decoration-line: underline;
    }
  }
}

/*
table {
  width: 100%;
  border-spacing: 0 0.5rem;
  border-collapse: separate;
  table-layout: fixed;
}
*/
.modal-content {
  box-shadow: 0 4px 30px rgb(0 0 0 / 50%);
  border: none !important;
}
