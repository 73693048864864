/* You can add global styles to this file, and also import other style files */
@import "bootstrap";
@import "colors";
@import "layout";
@import "buttons";
@import "img";

html,
body {
  background-color: $background;
  color: $text;
  font-family: Roboto, sans-serif;
  font-size: 15px;
  line-height: 28px;
  font-weight: 600;
}

.w-fit {
  width: fit-content;
}

.pageTitle {
  font-size: 2rem;
  font-weight: 600;
}

.mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #fff;
  --mdc-snackbar-supporting-text-color: #fff;

  &.app-notification-error {
    --mdc-snackbar-container-color: #f23a2f;
  }

  &.app-notification-success {
    --mdc-snackbar-container-color: #43a446;
  }

  &.app-notification-warning {
    --mdc-snackbar-container-color: #fcc11f;
  }
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #172c6d;
  --mdc-protected-button-label-text-color: #fff;
}

.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #989898;
  --mdc-protected-button-label-text-color: #fff;
}

.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #f23a2f;
  --mdc-protected-button-label-text-color: #fff;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white !important;
}
